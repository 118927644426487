import {Button, Form, Input} from 'antd';
import styled from 'styled-components';

export const onlyLettersReg = /[A-Za-z]/;
export const onlySpacesReg = /\s/;

export const fetchErrorMessage = (key, errorFieldMessages) => {
  let error;
  if (errorFieldMessages) {
    error = errorFieldMessages?.find(value => {
      if (value[key]) {
        return true;
      }
    });
  }

  return error ? error[key] : null;
};

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 7px;
  width: 100%;
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
  .ant-form-item-explain-error {
    text-align: start;
  }
  .form-control {
    background-color: #936BDA12 !important;
  }
`;

export const ContinueButton = styled(Button)<{loadingStyle?: string; brandColor?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  width: 100%;
  height: 54px;
  border-radius: 10px;
  background: ${p => p.brandColor ? `${p.brandColor} !important` : 'linear-gradient(85.22deg, #7B42CA 0%, #A25AD1 100%) !important'};
  color: #fff !important;
  border: 1px solid ${p => p.brandColor ? `${p.brandColor} !important` : '#A25AD1 !important'};
  font-family: inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.91px;
  text-align: left;
  ${p => p.loadingStyle ? p.loadingStyle : ''}
  &:hover, &:focus {
    background-color: ${p => p.brandColor ? `${p.brandColor} !important` : '#63429F !important'};
    color: #fff !important;
    border-color: ${p => p.brandColor ? `${p.brandColor} !important` : '#63429F !important'};
  }
  @media screen and (max-width: 1030px) {
    height: 45px !important;
    font-size: 14px;
  }
  @media screen and (max-width: 800px) {
    height: 48px !important;
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
    height: 40px !important;
    font-size: 12px;
  }
`;

export const Terms = styled.div`
  margin: 12px 8px 47px -2px;
  width: 100%;
  font-family: inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: #4E5156;
  opacity: 0.7;
  a {
    text-decoration: underline;
    color: #4E5156;
  }
  div {
    white-space: nowrap;
  }
  @media screen and (max-width: 810px) {
    font-size: 13px !important;
    line-height: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 11px !important;
    line-height: 14px;
  }
  @media screen and (max-width: 400px) {
    font-size: 9px !important;
    line-height: 14px;
  }
`;

export const AccountInput = styled(Input)`
width: 100%;
height: 48px !important;
background: #FFFFFF;
border: 1px solid #BFBFBF;
color: #121212 !important;
border-radius: 10px !important;
font-family: inter !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: 22.4px !important;
text-align: left !important;
box-shadow: 0px 3px 2px 0px #00000005;
padding: 4px 16px;
.ant-input {
  background: transparent !important;
  // border: 1px solid #9E85CC69;
  color: #121212;
}
&:hover, &:focus {
  background: #FFFFFF19 !important;
  border: 1px solid #BFBFBF;
}
&:disabled,
&.ant-input[disabled], .ant-input-affix-wrapper-disabled {
  background-color: rgba(147, 107, 218, 0.07) !important;
  border-color: rgba(158, 133, 204, 0.41) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

&:-webkit-autofill,
&:-webkit-autofill:hover, 
&:-webkit-autofill:focus, 
&:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #121212 !important;
    background-color: transparent;
}
.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover, 
.ant-input:-webkit-autofill:focus, 
.ant-input:-webkit-autofill:active  {
    transition: background-color 5000s;
    -webkit-text-fill-color: #121212 !important;
    background-color: transparent !important;
}
@media screen and (max-width: 1030px) {
  height: 45px !important;
  font-size: 14px !important;
  &::placeholder {
    font-size: 14px !important;
  }
.ant-input::placeholder {
  font-size: 14px !important;
}
}
@media screen and (max-width: 810px) {
  height: 48px !important;
  font-size: 14px !important;
  &::placeholder {
    font-size: 14px !important;
  }
.ant-input::placeholder {
  font-size: 14px !important;
}
}
@media screen and (max-width: 600px) {
  height: 40px !important;
  font-size: 12px !important;
  &::placeholder {
    font-size: 12px !important;
  }
.ant-input::placeholder {
  font-size: 12px !important;
}
}
`;

export const FormContainer = styled.div<{brandColor?:string}>`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 450px;
border-radius: 16px;
z-index: 2;
background: #F9F9FB;
box-shadow: 0px 20px 250px 250px #0000008F;
padding: 25px;
@media screen and (max-width: 810px) {
  max-width: 556px !important;
  width: 90% !important;
  padding: 25px !important;
  .ant-form-item-explain-error {
    font-size: 12px;
  }
  .ant-form-item-explain {
    min-height: auto !important;
  }
  input::placeholder {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 600px) {
  padding: 10px 0px !important;
  .ant-form-item-explain-error {
    font-size: 10px;
  }
}
`;

export const HeadingAnimationWrapper = styled.div`
  position: relative;

  .fade {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    min-width: 100%;
    z-index: 0;
    user-select: none;
    &.visible {
      transition-delay: 0.1s;
      opacity: 1;
      z-index: 1;
    }

    &.hidden {
      opacity: 0;
    }
  }
`;

