import React, {useState} from 'react';
import styles from './styles.module.scss';
import {Col, Form} from 'antd';
import {AccountInput, ContinueButton, FormContainer, HeadingAnimationWrapper, StyledFormItem, Terms} from './helpers';
import Image from 'next/image';
import {faArrowLeft, faEye, faEyeSlash} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import {linkgraphDomains} from '@/utils/router';
import styled from 'styled-components';
import {getDomain, getSingleUrlParam} from '@/utils/url';
import {metaClickEvents} from '@/utils/functions';
import {updateGTMDatalayer} from '@/utils/gtm';
import {faKey} from '@fortawesome/pro-regular-svg-icons';
import {LogoWrapper} from '../components/logo-wrapper/logoWrapper';

const StepTwo = ({setStepData, stepData}) => {
  const [showPass, setShowPass] = useState(false);
  const [form] = Form.useForm();
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  const {settings: {customer: {profile: {brandColor, logo, signUpLogo}}}} = useStore('');
  const invitationToken = getSingleUrlParam('invitation_token');

  const validatePassword = (_, value) => {
    if (!value.length) {
      return Promise.reject(new Error('Enter a password'));
    }
    if (/\s/.test(value)) {
      return Promise.reject(new Error('Password cannot contain spaces'));
    }
    if (value.length < 8) {
      return Promise.reject(new Error('Use 8 characters or more for your password'));
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className={styles.changeBg}>
        {(isWhiteLabelDomain && brandColor) && (
          <>
            <div className={styles.bgBack}></div>
            <div className={styles.bgFront} style={{'--brand-color': brandColor} as any}></div>
          </>
        )}
        <div
          className={styles.bgGradient}
        />
        <div className={styles.formContainer}>
          <div
            style={{height: '22px'}}
          />
          <LogoWrapper>
            <NextImgStyled className={invitationToken && styles.invitationLogo} src={isWhiteLabelDomain ? (signUpLogo ?? logo) : '/img/searchatlasLogo.svg'} />
          </LogoWrapper>
          {invitationToken ? <div className={styles.invitationTitle}>Accept your Invitation</div> : <div className={styles.descriptionContainer}>
            <div className={styles.container}>
              <HeadingAnimationWrapper>
                <div style={{padding: '0 20px'}}>
                  <p className={styles.text}>Automate your SEO {isWhiteLabelDomain ? '' : 'with '} {isWhiteLabelDomain ? '' : <span className={styles.descLineTwo}> Search Atlas</span>}.</p>
                </div>
              </HeadingAnimationWrapper>
              <div id='console' className='console-underscore'></div>
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.description}>Our SEO tools automate thousands of hours of work to deliver organic growth quickly.</div>
              <div className={styles.description}>Stop wasting time and money on your SEO and transform your strategy in 7 days.</div>
            </div>
          </div>}
          <FormContainer brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''}>
            <div className={styles.formTitle}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                color='#121212'
                fontSize={20}
                style={{opacity: 0.5, cursor: 'pointer'}}
                onClick={() => setStepData({step: 'stepOne', data: {...stepData.data}})}
              />
              <p style={{marginBottom: 'unset', textAlign: 'center', width: '100%'}}>Enter a password</p>
            </div>
            <StyledForm className={styles.form} form={form}
              onFinish={(values: any) => {
                setStepData({step: 'stepThree', data: {...stepData.data, ...values}});
                metaClickEvents('click_register_step_2', {step: 'step 2', password: ''});
                updateGTMDatalayer({
                  event: 'Next',
                });
                updateGTMDatalayer({'event': 'password added', 'step': 'step_2', 'password': ''});
              }}
              initialValues={{password: stepData?.data ? stepData?.data?.password : ''}}
            >
              <Col span={24}>
                <StyledFormItem
                  name='password'
                  rules={[
                    {validator: validatePassword},
                  ]}
                >
                  <AccountInput
                    type={showPass ? 'text' : 'password'}
                    placeholder={'Password'}
                    prefix={<FontAwesomeIcon color='#4E5156' icon={faKey} style={{marginRight: '10px'}}/>}
                    suffix={showPass ? <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faEyeSlash} onClick={() => setShowPass(false)} /> : <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faEye} onClick={() => setShowPass(true)} /> }
                  />
                </StyledFormItem>
              </Col>
              <Col>
                <ContinueButton brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''} htmlType='submit' style={{marginBottom: '22px'}}>
                  <div>Next</div>
                  <Image alt='searchAtlasIcon' src='/img/registerBg/searchAtlasIcon.svg' width={16} height={16} />
                </ContinueButton>
              </Col>
              {!isWhiteLabelDomain && <Col>
                <Terms>
                  <div>By continuing, you agree to Search Atlas’s <a href='https://www.linkgraph.com/terms-of-service/' target='_blank' rel='noreferrer'>Consumer Terms</a></div>
                  <div>and acknowledge their <a href='https://www.linkgraph.com/privacy-policy/' target='_blank' rel='noreferrer'>Privacy Policy</a>.</div>
                </Terms>
              </Col>}
              <Col>
                <div className={styles.footerContainer}>
                  <img className={styles.firstFooterImage} src={'/img/registerBg/footer-icons-1.svg'} />
                  <img style={{width: '70%', height: 28}} src={'/img/registerBg/footer-icons-2.svg'} />
                </div>
              </Col>
            </StyledForm>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default StepTwo;

export const StyledForm = styled(Form)`
  width: 100%;
  @media screen and (max-width: 600px) {
    width: 90% !important;
  }
`;

const NextImgStyled = styled.img`
  width: 260px;
  height: auto;
  position: relative;
  z-index: 3;
  margin-bottom: 47px;
  margin-top: 40px;
  @media screen and (max-width: 810px) {
    margin-top: 16px !important;
    margin-bottom: 28px !important;
    width: 220px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 10px !important;
    margin-bottom: 40px !important;
    width: 200px;
  }
`;
