import React from 'react';
import styles from './styles.module.scss';
import {Col, Form, Spin} from 'antd';
import {ContinueButton, FormContainer, HeadingAnimationWrapper, StyledFormItem, Terms} from './helpers';
import Image from 'next/image';
import PhoneInput from 'react-phone-input-2';
import {LoadingOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {linkgraphDomains} from '@/utils/router';
import {getDomain, getSingleUrlParam} from '@/utils/url';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {metaClickEvents} from '@/utils/functions';
import {LogoWrapper} from '../components/logo-wrapper/logoWrapper';

const StepThree = ({setStepData, stepData, onRegister, loading}) => {
  const [form] = Form.useForm();
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  const {settings: {customer: {profile: {brandColor, logo, signUpLogo}}}} = useStore('');
  const invitationToken = getSingleUrlParam('invitation_token');

  return (
    <>
      <div className={styles.changeBg}>
        {(isWhiteLabelDomain && brandColor) && (
          <>
            <div className={styles.bgBack}></div>
            <div className={styles.bgFront} style={{'--brand-color': brandColor} as any}></div>
          </>
        )}
        <div className={styles.formContainer}>
          <div
            style={{height: '22px'}}
          />
          <LogoWrapper>
            <NextImgStyled className={invitationToken && styles.invitationLogo} src={isWhiteLabelDomain ? (signUpLogo ?? logo) : '/img/searchatlasLogo.svg'} />
          </LogoWrapper>
          {invitationToken ? <div className={styles.invitationTitle}>Accept your Invitation</div> : <div className={styles.descriptionContainer}>
            <div className={styles.container}>
              <HeadingAnimationWrapper>
                <div style={{padding: '0 20px'}}>
                  <p className={styles.text}>Automate your SEO {isWhiteLabelDomain ? '' : 'with '} {isWhiteLabelDomain ? '' : <span className={styles.descLineTwo}> Search Atlas</span>}.</p>
                </div>
              </HeadingAnimationWrapper>
              <div id='console' className='console-underscore'></div>
            </div>
            <div className={styles.descriptionWrapper}>
              <div className={styles.description}>Our SEO tools automate thousands of hours of work to deliver organic growth quickly.</div>
              <div className={styles.description}>Stop wasting time and money on your SEO and transform your strategy in 7 days.</div>
            </div>
          </div>}
          <FormContainer brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''}>
            <div className={styles.formTitle}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                color='#121212'
                fontSize={20}
                style={{opacity: 0.5, cursor: 'pointer'}}
                onClick={() => setStepData({step: 'stepTwo', data: {...stepData.data}})}
              />
              <p style={{marginBottom: 'unset', textAlign: 'center', width: '100%'}}>What&apos;s your phone number?</p>
            </div>
            <StyledForm className={styles.form} form={form}
              onFinish={(values: any) => {
                setStepData({...stepData, data: {...stepData.data, ...values}});
                metaClickEvents('click_register_step_3', {step: 'step 3', phone: values.phoneNumber});
                onRegister(values.phoneNumber);
              }}
              initialValues={{phoneNumber: stepData?.data ? stepData.data.phoneNumber : ''}}
            >
              <Col span={24}>
                <StyledFormItem
                  className={styles.phoneInput}
                  style={{marginBottom: '5px'}}
                  name='phoneNumber'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter phone number',
                    },
                  ]}
                >
                  <StyledPhoneInput
                    country={'us'}
                    inputStyle={{width: '100%'}}
                    placeholder={'Phone Number'}
                    onChange={e => setStepData({...stepData, data: {...stepData.data, phoneNumber: e}})}
                    value={stepData.data.phoneNumber}
                  />
                </StyledFormItem>
              </Col>
              <Col>
                <ContinueButton brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''} htmlType='submit' style={{marginBottom: '0px', marginTop: '18px'}} disabled={loading} loadingStyle={loading ? 'opacity: 0.7;' : ''}>
                  <div>Submit</div>
                  {loading ? <Spin indicator={<LoadingOutlined style={{color: '#ffff', fontSize: '20px'}} spin />} /> : <Image alt='searchAtlasIcon' src='/img/registerBg/searchAtlasIcon.svg' width={16} height={16} />}
                </ContinueButton>
              </Col>
              <Col>
                <Terms style={{marginTop: '20px'}}>
                  <div>SMS & data charges may apply. You can unsubscribe at any time.</div>
                </Terms>
              </Col>
              <Col>
                <div className={styles.footerContainer}>
                  <img className={styles.firstFooterImage} src={'/img/registerBg/footer-icons-1.svg'} />
                  <img style={{width: '70%', height: 28}} src={'/img/registerBg/footer-icons-2.svg'} />
                </div>
              </Col>
            </StyledForm>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default StepThree;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    background-color: #FFFFFF !important;
    color: #121212;
    border: 1px solid #BFBFBF;
    box-shadow: 0px 3px 2px 0px #00000005;
  }
  .country-list {
    text-align: start !important;
  }
  .form-control::placeholder {
    color: #121212 !important;
    opacity: 1;
  }
  .arrow {
    color: #121212 !important;
    border-top: 4px solid #121212 !important;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  @media screen and (max-width: 600px) {
    width: 90% !important;
  }
`;

const NextImgStyled = styled.img`
  width: 260px;
  height: auto;
  position: relative;
  z-index: 3;
  margin-bottom: 47px;
  margin-top: 40px;
  @media screen and (max-width: 810px) {
    margin-top: 16px !important;
    margin-bottom: 28px !important;
    width: 220px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 10px !important;
    margin-bottom: 40px !important;
    width: 200px;
  }
`;
